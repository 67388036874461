import tw, { styled } from 'twin.macro'

export const NavBar = styled.nav`
  ${tw`grid [grid-template-rows:72px] bg-black opacity-95 text-white lg:[scroll-snap-align:start] sticky [top:0] w-full [height:72px] z-10`}

  > div {
    ${tw`px-6 lg:px-10 flex items-center justify-between`}
  }

  a {
    ${tw`no-underline`}
  }
`

export const Logo = styled.h1`
  ${tw`flex items-center text-4xl text-white`}

  svg {
    ${tw`h-10`}
    path {
      fill: url('#custodia-mark_svg__a');
    }
  }

  span {
    ${tw`ml-2 font-bold`}
  }
`

export const NavItemTitle = styled.div(
  ({ isSelected }: { isSelected: boolean }) => [
    tw`text-white px-3 py-1 hover:opacity-70`,
    isSelected && tw`font-bold`,
  ],
)

export const DesktopRightContent = styled.div`
  ${tw`flex`}
`

export const DesktopNavItems = styled.ul`
  ${tw`hidden lg:grid grid-cols-[repeat(3, auto)] gap-12 items-center text-xl list-none`}
`

export const MobileNavItems = styled.ul(
  ({ menuOpen }: { menuOpen: boolean }) => [
    tw`hidden items-center text-xl px-2 py-4 [margin-top:-0.1rem] list-none`,
    tw`sticky [top:72px] z-10 w-full h-[calc(100vh - 72px + 0.1rem)] left-0 bg-black`,
    menuOpen && tw`flex flex-col lg:hidden gap-7 items-start text-xl`,
  ],
)

export const MobileMenuSpacer = styled.div`
  ${tw`w-full [flex-grow:1]`}
`

export const MobileSubscribeButtonWrapper = styled.div`
  ${tw`w-full pb-4 px-2`}

  button {
    ${tw`w-full block`}
  }
`

export const DarkModeButton = styled.button`
  ${tw`ml-6 hidden lg:block`}
`

export const MenuButton = styled.button`
  ${tw`lg:hidden p-1`}
`
