import React, { useEffect, useState } from 'react'
import { Shell } from '~/components/molecules/Shell'
import * as S from './styled'
import { InformationSection } from '~/components/molecules/InformationSection'

export const Screen = () => {
  const [hydrated, setHydrated] = useState<boolean>(false)

  useEffect(() => {
    setHydrated(true)
  }, [])

  if (!hydrated) {
    return null
  }

  return (
    <Shell>
      <S.Screen>
        <S.FeatureImageSection>
          <S.FeatureImageWrapper>
            <S.FeatureImage
              alt={'tetons'}
              src={'/images/home/tetons.webp'}
              layout={'fill'}
              objectFit={'cover'}
              objectPosition={'50% 26%'}
              priority={true}
            />
          </S.FeatureImageWrapper>
          <S.FeatureImageOpacityOverlay />
          <S.FeatureImageOverlayWrapper>
            <S.FeatureImageOverlay>
              <h1>
                Custodia Bank™ specializes in digital asset payment and custody
                solutions for U.S. commercial customers.
              </h1>
            </S.FeatureImageOverlay>
          </S.FeatureImageOverlayWrapper>
        </S.FeatureImageSection>

        <InformationSection
          imageSrc={'/images/home/chess.webp'}
          header={
            'A regulatorily compliant platform for businesses to manage cash, connect and transact.'
          }
          highlightHeader={true}
          objectPosition={'bottom'}
        >
          <p>
            As a chartered bank, Custodia operates under the first special
            purpose depository institution legal and regulatory framework in the
            United States.
          </p>
          <p>
            Custodia services U.S.&ndash;based business customers such as
            digital asset businesses, fintechs, banks, corporate treasurers,
            trusts, pension funds, start-ups, and many more. Ultimately, the
            strategy of Custodia is to serve customers worldwide.
          </p>
        </InformationSection>

        <InformationSection
          imageSrc={'/images/home/vault-door.webp'}
          imageAttribution={{
            content: 'Photo (edited) by danielsphotography / CC BY 2.0',
            link: 'https://creativecommons.org/licenses/by/2.0/',
          }}
          header={
            'We see a future where all banks support digital assets and offer meaningful API capabilities to tech-savvy customers.'
          }
        >
          <p>
            Custodia is a new breed of bank &mdash; a software platform with a
            bank charter, built to connect digital assets with the traditional
            financial system in a safe and sound way. Custodia has a deep
            understanding of how digital assets can solve payment inefficiencies
            faced by our business customers. Custodia is a depository
            institution, which means we’re eligible to become a U.S. dollar
            clearing bank at the Federal Reserve and, consequently, Custodia
            promises a superior regulatory structure relative to digital asset
            competitors.
          </p>
        </InformationSection>
      </S.Screen>
    </Shell>
  )
}
