import React, { useState } from 'react'
import * as S from './styled'
import { VscClose, VscThreeBars } from 'react-icons/vsc'
import Link from 'next/link'
import { useRouter } from 'next/router'
import CustodiaMark from '~/assets/custodia-mark.svg'
import { ColoredBorderWrapper } from '~/components/molecules/ColoredBorderWrapper'

const ROUTES = [
  {
    title: 'Press',
    href: '/press',
  },
  {
    title: 'About',
    href: '/about',
  },
]

export const NavBar = () => {
  const [menuOpen, setMenuOpen] = useState(false)

  return (
    <S.NavBar>
      <div>
        <Link href={'/'}>
          <S.Logo>
            <CustodiaMark />
            <span>Custodia</span>
          </S.Logo>
        </Link>
        <S.DesktopRightContent>
          <S.MenuButton
            onClick={() => setMenuOpen(!menuOpen)}
            aria-expanded={!!menuOpen}
            aria-label="Menu"
            aria-controls="nav-menu"
          >
            {menuOpen ? <VscClose size={25} /> : <VscThreeBars size={25} />}
          </S.MenuButton>
          <S.DesktopNavItems>
            {ROUTES.map((r) => (
              <NavItem key={r.href} href={r.href} title={r.title} />
            ))}
          </S.DesktopNavItems>
        </S.DesktopRightContent>
      </div>
      <S.MobileNavItems menuOpen={menuOpen} id={'nav-menu'}>
        {ROUTES.map((r) => (
          <NavItem key={r.href} href={r.href} title={r.title} />
        ))}
        <S.MobileMenuSpacer />
      </S.MobileNavItems>
    </S.NavBar>
  )
}

interface DesktopNavItemProps {
  href: string
  title: string
}

const NavItem = ({ href, title }: DesktopNavItemProps) => {
  const router = useRouter()
  const isCurrentRoute = router.route === href

  return (
    <li>
      <Link href={href}>
        <span>
          {isCurrentRoute ? (
            <ColoredBorderWrapper>
              <S.NavItemTitle isSelected={isCurrentRoute}>
                {title}
              </S.NavItemTitle>
            </ColoredBorderWrapper>
          ) : (
            <S.NavItemTitle isSelected={isCurrentRoute}>{title}</S.NavItemTitle>
          )}
        </span>
      </Link>
    </li>
  )
}
