import React, { useMemo } from 'react'
import Image, { ImageLoader, ImageProps } from 'next/legacy/image'

/*
    Based on Example laid out by Cloudflare:
    https://developers.cloudflare.com/images/image-resizing/integration-with-frameworks/
*/
const normalizeSrc = (src) => {
  return src.startsWith('/') ? src.slice(1) : src
}
const cloudflareLoader: ImageLoader = ({ src, width, quality }) => {
  const params = ['fit=scale-down', `width=${width}`]
  if (quality) {
    params.push(`quality=${quality}`)
  }
  const paramsString = params.join(',')
  return `/cdn-cgi/image/${paramsString}/${normalizeSrc(src)}`
}

const localLoader: ImageLoader = ({ src }) => `/${normalizeSrc(src)}`

const cloudflareEnvs = ['staging', 'production', 'eks-stage']
const localEnvs = ['local', 'test']

export const NextOptimizedImage = ({
  appEnv,
  ...rest
}: ImageProps & { appEnv: string }) => {
  const imageLoader = useMemo(
    () =>
      cloudflareEnvs.includes(appEnv)
        ? cloudflareLoader
        : localEnvs.includes(appEnv)
        ? localLoader
        : undefined,
    [appEnv],
  )
  return <Image loader={imageLoader} {...rest} />
}
