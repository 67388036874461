import React from 'react'
import * as S from './styled'

interface Props {
  children: React.ReactNode
}

export const ColoredBorderWrapper = ({ children }: Props) => (
  <div>
    {children}
    <S.Border />
  </div>
)
