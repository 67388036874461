import React from 'react'
import { NavBar } from '~/components/molecules/NavBar'
import classNames from 'classnames'
import Footer from '@package/ds/footer'
import * as S from './styled'
import { useIsBrowser } from '~/hooks/useIsBrowser'
import CookieBanner from '@package/ds/cookie-banner'

interface Props {
  enableSnapPoints?: boolean
  children: React.ReactNode
}

export const Shell = ({ enableSnapPoints = false, children }: Props) => {
  const isBrowser = useIsBrowser()

  return (
    <>
      <S.ShellWrapper
        className={classNames({ dark: true })}
        enableSnapPoints={enableSnapPoints}
      >
        <NavBar />
        <S.ContentWrapper>{children}</S.ContentWrapper>
        <Footer />
        {isBrowser && <CookieBanner />}
      </S.ShellWrapper>
    </>
  )
}
