import tw, { styled } from 'twin.macro'
import { OptimizedImage } from '~/components/molecules/OptimizedImage'

export const InformationSection = styled.section(
  ({
    topMargin,
    highlightHeader,
  }: {
    topMargin: boolean
    highlightHeader: boolean
  }) => [
    tw`grid grid-cols-1 lg:grid-cols-2 lg:grid-rows-[minmax(calc(100vh - 72px), min-content)] w-full lg:[scroll-snap-align:start]`,
    topMargin && !highlightHeader && tw`mt-24`,
    highlightHeader && tw`mt-8`,
    highlightHeader && topMargin && tw`lg:mt-24`,
  ],
)

export const InformationImageWrapper = styled.div`
  ${tw`w-full h-[28rem] short:h-[25rem] md:h-[40rem] lg:h-full lg:max-h-[calc(100vh - 72px)]`}
  ${tw`lg:sticky lg:[top:72px] lg:h-[calc(100vh - 72px)] relative grid`}
  
  > * {
    grid-column: 1 / span 1;
    grid-row: 1 / span 1;
  }
`

export const InformationImage = styled(OptimizedImage)`
  ${tw`bg-gold-500 dark:bg-taupe-500`}
`

export const LinkImageAttribution = styled.a`
  ${tw`z-10 place-self-end`}

  > div {
    ${tw`bg-charcoal-900 text-white text-xs opacity-40 px-1`}
  }
`

export const ImageAttribution = styled.div`
  ${tw`z-10 place-self-end bg-charcoal-900 text-white text-xs opacity-40 px-1`}
`

export const HighlightedHeader = styled.h2`
  ${tw`lg:hidden text-2xl px-4 pb-8 dark:text-white`}
`

export const InformationContent = styled.div`
  ${tw`p-6 md:px-10 xl:px-16 lg:py-8 self-center dark:text-white`}
  ${tw`text-xl`}

  > p:not(:first-of-type) {
    ${tw`pt-6`}
  }

  > ul {
    ${tw`pt-6 list-disc list-outside [padding-inline-start:2rem]`}
  }
`

export const InformationContentHeader = styled.h2(
  ({ highlightHeader }: { highlightHeader: boolean }) => [
    tw`pb-6 2xl:pb-8`,
    tw`text-3xl 2xl:text-4xl`,
    highlightHeader && tw`hidden lg:block`,
  ],
)
