import tw, { styled } from 'twin.macro'
import { OptimizedImage } from '~/components/molecules/OptimizedImage'

export const Screen = styled.div`
  ${tw`flex flex-col`}
`

export const FeatureImageSection = styled.section`
  ${tw` w-full text-white`}
  ${tw`grid grid-cols-1 [grid-template-rows:36rem] short:[grid-template-rows:auto]`}
  ${tw`lg:[grid-template-rows:40rem] short:lg:[grid-template-rows:minmax(34rem,_calc(100vh_-_72px))]`}
  ${tw`2xl:[grid-template-rows:46rem] short:2xl:[grid-template-rows:minmax(36rem,_calc(100vh_-_72px))]`}
`

export const FeatureImageWrapper = styled.div`
  ${tw`[grid-column:1_/_span_1] [grid-row:1_/_span_1]`}
  ${tw`w-full relative [z-index:1]`}
`

export const FeatureImage = styled(OptimizedImage)`
  ${tw`bg-gold-500 dark:bg-taupe-500`}
`

export const FeatureImageOpacityOverlay = styled.div`
  ${tw`[grid-column:1_/_span_1] [grid-row:1_/_span_1] [z-index:2]`}
  background: linear-gradient(to right, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
`

export const FeatureImageOverlayWrapper = styled.div`
  grid-column: 1 / span 1;
  grid-row: 1 / span 1;
  ${tw`self-center w-full`}
  ${tw`px-6 lg:px-20 py-16 [z-index:3]`}
`

export const FeatureImageOverlay = styled.div`
  ${tw`max-w-xl 2xl:max-w-3xl flex flex-col gap-12`}

  > h1 {
    ${tw`text-4xl 2xl:text-5xl md:font-semibold`}
  }

  > p {
    ${tw`text-xl 2xl:text-2xl`}
  }
`

export const FeatureImageSubscribeButtonWrapper = styled.div`
  ${tw`lg:hidden block`}

  > a {
    ${tw`no-underline`}
  }
`

export const ProductsSection = styled.section`
  ${tw`grid grid-cols-1 [grid-template-rows:repeat(2,_min-content)]`}
  ${tw`xl:[grid-template-rows:1fr_min-content_2fr_min-content_1fr] short:xl:[grid-template-rows:repeat(2,_min-content)] short:xl:gap-4`}
  ${tw`w-full xl:h-[calc(100vh - 72px)] short:xl:h-[min-content] xl:[scroll-snap-align:start] dark:text-white`}
  ${tw`mt-24 px-6 lg:mb-24 xl:px-10 xl:my-0 short:xl:mt-24`}

  > h2 {
    ${tw`text-center text-4xl lg:text-5xl italic lg:font-semibold xl:self-end pb-8`}
    ${tw`[grid-column:1_/_span_1] [grid-row:1_/_span_1]`}
    ${tw`xl:[grid-row:2_/_span_1] short:xl:[grid-row:1_/_span_1]`}
  }
`

export const ProductsSectionImage = styled.img`
  ${tw`[grid-column:1_/_span_1] [grid-row:2_/_span_1] [height:25%] object-cover`}
  ${tw`sm:h-3/6 sm:self-start`}
  ${tw`xl:[grid-row:1_/_span_5] xl:h-[calc(100vh - 72px)] xl:object-scale-down short:xl:object-cover short:xl:[grid-row:1_/_span_2]`}
  ${tw`pt-12 xl:py-8 w-full opacity-10 dark:[opacity:0.07]`}
`

export const ProductsGrid = styled.div`
  ${tw`grid justify-items-center mt-28 sm:grid-cols-2 xl:grid-cols-2 sm:gap-6 xl:gap-x-12 lg:gap-y-16 w-full dark:text-white`}
  ${tw`[grid-column:1_/_span_1] [grid-row:2_/_span_1]`}
  ${tw`xl:[grid-row:3_/_span_1] short:xl:[grid-row:2_/_span_1]`}
`

export const ProductsGridItem = styled.div`
  ${tw`flex flex-col pt-12 lg:pt-0 [height:fit-content]`}

  > img {
    ${tw`h-20 md:h-24 mx-auto`}
  }

  > h3 {
    ${tw`text-center py-8 lg:py-12 w-full max-w-xs text-3xl font-semibold`}
  }

  > div {
    ${tw`max-w-xs md:mx-auto pl-8`}
    ${tw`2xl:text-xl`}

    span {
      font-family: 'Lora', serif;
      ${tw`text-xs 2xl:text-base`}
    }
  }

  &:nth-child(2n) {
    > div {
      ${tw`md:pl-0`}
    }
  }

  &:nth-child(3n) {
    ${tw`sm:col-span-2 xl:col-auto`}
  }
`

export const AsSeenOnBannerWrapper = styled.div`
  ${tw`bg-charcoal-900 px-6 lg:px-10`}
`

export const AsSeenOnBanner = styled.ul`
  ${tw`list-none grid grid-cols-4 items-center gap-x-10 lg:gap-x-20 py-6`}
  ${tw`mx-auto [width:fit-content]`}
  
  img {
    ${tw`object-scale-down`}
  }
`
