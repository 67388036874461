import React from 'react'
import * as S from './styled'

interface Props {
  header?: string
  topMargin?: boolean
  children: React.ReactNode
  highlightHeader?: boolean
  // Props to control the image:
  imageSrc: string
  imageAttribution?: {
    content: string
    link?: string
  }
  objectFit?: React.CSSProperties['objectFit']
  objectPosition?: React.CSSProperties['objectPosition']
  priority?: boolean
}

export const InformationSection = ({
  imageSrc,
  imageAttribution,
  header,
  topMargin = true,
  children,
  highlightHeader = false,
  objectFit = 'cover',
  objectPosition,
  priority = false,
}: Props) => (
  <S.InformationSection topMargin={topMargin} highlightHeader={highlightHeader}>
    {highlightHeader && header != null && (
      <S.HighlightedHeader>{header}</S.HighlightedHeader>
    )}
    <S.InformationImageWrapper>
      <S.InformationImage
        alt={''}
        src={imageSrc}
        priority={priority}
        objectFit={objectFit}
        objectPosition={objectPosition}
        layout={'fill'}
      />
      {imageAttribution != null &&
        (imageAttribution.link != null ? (
          <S.LinkImageAttribution
            href={imageAttribution.link}
            target={'_blank'}
            rel={'noreferrer noopener'}
          >
            <div>{imageAttribution.content}</div>
          </S.LinkImageAttribution>
        ) : (
          <S.ImageAttribution>{imageAttribution.content}</S.ImageAttribution>
        ))}
    </S.InformationImageWrapper>
    <S.InformationContent>
      {header != null && (
        <S.InformationContentHeader highlightHeader={highlightHeader}>
          {header}
        </S.InformationContentHeader>
      )}
      {children}
    </S.InformationContent>
  </S.InformationSection>
)
